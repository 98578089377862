<template>
  <CBox
    w="100%"
    pb="0.7em"
    mb="1em"
    borderBottom="1px solid"
    borderColor="breadcrumbNotActive2"
  >
    <CFlex @click="goBack()" pl="5px" align="center" cursor="pointer">
      <CIcon name="arrow-left" size="24px" mr="2" />
      <CText fontFamily="Montserrat" fontSize="20px" fontWeight="700">
        Back
      </CText>
    </CFlex>
  </CBox>
</template>

<script>
export default {
  name: "BackHeader",
  methods: {
    goBack() {
      window.history.back();
    },
  },
};
</script>
