<template>
  <CBox
    px="0.7em"
    py="0.4em"
    mr="0.4em"
    mb="0.4em"
    border="1px"
    borderRadius="md"
    borderColor="gray.200"
  >
    <CText fontFamily="Montserrat" fontSize="14px">{{ title }}</CText>
  </CBox>
</template>

<script>
import { CBox, CText } from "@chakra-ui/vue";

export default {
  name: "ButtonBlue",
  components: {
    CBox,
    CText,
  },
  props: {
    title: {
      type: String,
      default: "Label",
    },
  },
};
</script>
